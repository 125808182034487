import React from "react";
import Back from "../Common/back/Back";

const Quality = () => {
	return (
		<div>
			<Back title="Quality" />
		</div>
	);
};

export default Quality;
