import React from "react";
import Back from "../Common/back/Back";

const Manufac = () => {
	return (
		<div>
			<Back title="Manufacturing" />
		</div>
	);
};

export default Manufac;
